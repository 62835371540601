<div>
  <ems-table-record-count class="tbl-rec-count" [count]="totalClients" [showRecords]="totalClients && totalClients > 15"
                          (emitter)="pageObject.pageNo = 1;pageObject.pageSize = $event;setPaginationVal(); sharedService.clearSuccessMsgs(this.messageService, true);"
                          [showHeaderIcons]="true"></ems-table-record-count>
  <form [formGroup]="clientsForGlobalRule">
    <p-table [columns]="clientSelectionTableHeader" [value]="clientList" metaKeySelection="true" sortMode="multiple"
             [lazy]="true" (onLazyLoad)="customSort($event)" [customSort]="true" [(selection)]="selectedOrgIDArr"
             [multiSortMeta]="multiSortMeta" class="common-data-table manage-client-list-table">
      <ng-template pTemplate="header" let-columns>
        <tr class="filter-header-input">
          <th class="table-checkbox-selection filter-icon">
            <a (click)="toggleFunction()" class="cursor" href="javascript:void(0)">
              <em class="pi pi-filter"></em>
            </a>
          </th>
          <th class="table-checkbox-selection">
            <p-tableHeaderCheckbox class="emtr-checkbox" (click)="getSelectedMasterAccounts('')"></p-tableHeaderCheckbox>
          </th>
          <th *ngFor="let col of columns" [pSortableColumn]="(col.field !== 'masterAccount') && (col.field !== 'subAcctNum')?col.field:''"
              [pSortableColumnDisabled]="(col.field === 'masterAccount' || col.field === 'subAcctNum')"
              [ngClass]="[(totalClients && totalClients > 1) ? 'pointer-events-auto' : 'pointer-events-none', (col.field === 'clientName' || col.field === 'firstName') ? 'w-40-per' : '']">
            {{col.header}}
            <p-sortIcon [field]="col.field" *ngIf="col.field !== 'masterAccount' && col.field !== 'subAcctNum' && (totalClients && totalClients > 1)"></p-sortIcon>
          </th>
        </tr>
        <tr *ngIf="this.toggle" class="filtered-row-input">
          <th></th>
          <th></th>
          <th *ngFor="let col of columns" class="sdps-p-left_small">
            <input pInputText formControlName="masterAccount" class="w-100-per" type="text" name="filter"
                   (keydown.enter)="!(filterBtnDisable) ? applyFilter() : ''" onkeydown="return (event.keyCode !== 13);"
                   onkeypress="return (event.which >= 48 && event.which <= 57)" *ngIf="col.field === 'masterAccount' || col.field === 'subAcctNum'"
                   [ngClass]="{'invalidField': (clientsForGlobalRule.get('masterAccount').value && filterBtnDisable)}"
                   [maxLength]="8">
          </th>
          <th class="sdps-p-right_none">
            <button class="buttons button-filter"
                    [innerHTML]="emtrConstant.ruleFilter.clearButton" [disabled]="!clientsForGlobalRule.dirty && !isSortingChanged"
                    (click)="clearForm(isSortingChanged)"></button>
            <button class="buttons button-save button-filter" [disabled]="filterBtnDisable"
                    [innerHTML]="emtrConstant.ruleFilter.applyButton" (click)="applyFilter()"></button>
          </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td [attr.colspan]="columns.length + 3" class="sdps-text-center sdps-font-weight-bold"
              [innerHTML]="emtrConstant.rulesConstant.autoComplete.noRecords"></td>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr [pSelectableRow]="rowData">
          <td class="table-checkbox-selection filter-icon"></td>
          <td>
            <p-tableCheckbox class="emtr-checkbox" (click)="getSelectedMasterAccounts(rowData)"
                             [value]="rowData.subAcctNum"></p-tableCheckbox>
          </td>
          <td *ngFor="let col of columns" [ngClass]="col.field === 'firstName' ? 'word-break' : ''">
            <div>
              <span [innerHTML]="rowData[col.field]"></span>
            </div>
          </td>
          <td></td>
        </tr>
      </ng-template>
    </p-table>
  </form>
  <p-paginator class="common-pagination" [alwaysShow]="totalClients && totalClients > 15 ? true : false" [rows]="pageObject.pageSize" [totalRecords]="totalClients"
               (onPageChange)="paginate($event);" #p></p-paginator>
</div>
