import {Component, OnInit, Input, ElementRef, Output, EventEmitter, ViewChild, OnDestroy} from '@angular/core';
import {empOrAccTableParticipantHeader} from '../../models/manage-employee-accounts-table-header';
import {SharedService} from '../../shared.service';
import {DatePipe} from '@angular/common';
import {SharedConstant} from '../../constants/sharedConstants';
import {
  empOrParticipantSecurityTableHeader
} from '../../models/magage-employee-group-accounts-table-header';
import {MessageService} from 'primeng/api'
import {Paginator} from 'primeng/paginator';
import {ManageEmpGrpService} from '../../services/manage-emp-grp-service/manage-emp-grp.service';
import {ManageEmployeeDataService} from '../../services/manage-employee/manage-employee-data.service';
import {Subscription} from 'rxjs';
import {CustomSortService} from '../../services/custom-sort/custom-sort.service';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {AccountNumberValidationService} from '../../services/accountNumberValidation/account-number-validation.service';

@Component({
  selector: 'ems-shared-manage-employee-accounts',
  templateUrl: './shared-manage-employee-accounts.component.html'
})
export class SharedManageEmployeeAccountsComponent implements OnInit, OnDestroy {

  @Input() getOrgId: any;
  @Input() showColumns: any = true;
  @Input() assignFormData: any;
  @Input() editGroupData: any;
  @Input() empAccformData: any;
  @Input() moduleType: any;
  @Output() selectedEmployeeAccounts: any = new EventEmitter();
  @Output() newlySelectedEmployeeAccounts: any = new EventEmitter();
  @Output() rowSelected: any = new EventEmitter();
  @Output() selectedAccounts: any = new EventEmitter();
  @Output() modifiedSubAccounts: any = new EventEmitter();
  @Output() employeeAccounts: any = new EventEmitter();
  @ViewChild('p', { static: true }) paginator: Paginator;
  employees: any[];
  totalRecords: any;
  noOfRecords: any = 15;
  selectedArr: any = [];
  pageVal: any = 1;
  empIds: any[] = [];
  getSharedConstant = SharedConstant;
  empAccntTblHeader: any = empOrAccTableParticipantHeader;
  manageEmpGrpAccTblHeader: any = empOrParticipantSecurityTableHeader;
  currentParentNode: any;
  empAccTableCols: any[];
  subAccntNumbers: any[] = [];
  chkCompanyGrp: any;
  validateCompanyGrp: any;
  child = 0;
  parent = 0;

  param = {
    orgId: '',
    pageNumber: 1,
    pageSize: 1,
    sortField: ''
  };

  totalPagesCount: any;
  pageNoCount: any;
  getCompanyGrpEmpCount: any = 0;
  getCompanyGrpAccCount: any = 0;
  sortField: any;
  pageNo: any = 1;
  toggle = false;
  errMsg = [];
  filterClicked = false;
  filterBtnDisableForFN = false;
  filterBtnDisableForLN = false;
  filterBtnDisableForEmpId = false;
  filterBtnDisableForacctHolderFN = false;
  filterBtnDisableForacctHolderLN = false;
  invalidEmpId = false;
  invalidFN = false;
  invalidLN = false;
  invalidacctHolderFN = false;
  invalidacctHolderLN = false;
  empAccountForm: UntypedFormGroup;
  isAccNumInvalid = false;
  selectedNewRecords = [];
  alreadySelectedRemoval: any = [];
  employeeSelection = '';
  removedExistingEmpId: any = [];
  subAccntNewlySelected: any = [];
  unModifiedRecords: any = [];
  selectedNoOfRecords = false;
  businessUnit: any;
  multiSortMeta: any = [];
  currentOrgSubscription: Subscription;
  isSortingChanged = false;
  defaultSortDataField = {field: 'updTs', order: -1};
  initialLoad = false;
  serviceOrgId: any;
  initialLoadData = true;

  constructor(public fb: UntypedFormBuilder, public customSortService: CustomSortService, public sharedService: SharedService, public datePipe: DatePipe, private el: ElementRef,
    public manageEmpGrpService: ManageEmpGrpService, public manageEmployeeDataService: ManageEmployeeDataService, private accountNumberValidationService: AccountNumberValidationService,
    public messageService: MessageService) {
  }

  paramObj(pageNo, records, sortCol) {
    return this.param = {
      orgId: this.serviceOrgId,
      pageNumber: pageNo,
      pageSize: records,
      sortField: sortCol
    };
  }

  ngOnDestroy(): void {
    if (this.currentOrgSubscription) {
      this.currentOrgSubscription.unsubscribe();
    }
    this.sharedService.isAccountNumInvalid = false;
  }

  ngOnInit() {
    this.selectedArr = this.empAccformData || [];
    this.initialLoad = true;
    this.createEmpAccountform();
    this.empIds = [];
    this.subAccntNumbers = [];
    this.multiSortMeta.push(this.defaultSortDataField);
    this.customSortService.sortArr = [];

    this.currentOrgSubscription = this.sharedService.clientInformation.subscribe(clientInfo => {
      if (clientInfo !== null) {
        this.serviceOrgId = clientInfo.orgId;
        this.businessUnit = clientInfo.businessUnit;
        this.sharedService.tableDropdownVal.next(15);
        this.empAccTableCols = this.businessUnit ? this.showColumns === false ? this.manageEmpGrpAccTblHeader[this.businessUnit] : this.empAccntTblHeader[this.businessUnit] : '';
      }
    });
    this.onChange();
    this.resetFilterForm();
  }

  createEmpAccountform() {
    this.empAccountForm = this.fb.group({
      empId: [''],
      acctNum: [''],
      firstName: [''],
      lastName: [''],
      acctHolderFn: [''],
      acctHolderLn: [''],
    });
  }

  resetFilterForm() {
    this.sharedService.resetFilterForm.subscribe(val => {
      /* if user changes the tab then it will reset the filter form for previous active tab but won't make any API call for that */
      if (val.tabChange && this.empAccountForm.dirty) {
        this.empAccountForm.reset();
        this.empAccountForm.markAsPristine();
        this.sharedService.clearValidationFields();
        this.filterBtnDisableForFN = false;
        this.filterBtnDisableForLN = false;
        this.filterBtnDisableForEmpId = false;
        this.filterBtnDisableForacctHolderFN = false;
        this.filterBtnDisableForacctHolderLN = false;
        this.invalidEmpId = false;
        this.invalidFN = false;
        this.invalidLN = false;
        this.invalidacctHolderFN = false;
        this.invalidacctHolderLN = false;
      }
      /* if user clicks to previous active tab then only it will make API call. API call will happen when toggle has been clicked. */
      if (val.tabName === this.sharedService.getSharedConstant[this.businessUnit].employeeOrParticipant && (this.toggle || this.filterClicked)) {
        this.filterClicked = false;
        this.toggle = false;
        const noOfRecords = this.param.pageSize;
        this.getEmpAccntResponse(this.paramObj(this.pageNo, noOfRecords, this.sortField), false);
      }
    });
  }

  getEmpAccntResponse(param, initialLoad) {
    const localUpdatedFormValues = this.empAccountForm.getRawValue();

    const filterObj: any = {
      orgId: this.serviceOrgId,
      empId: localUpdatedFormValues.empId ? localUpdatedFormValues.empId : null,
      accountNum: localUpdatedFormValues.acctNum ? localUpdatedFormValues.acctNum : null,
      firstName: localUpdatedFormValues.firstName ? localUpdatedFormValues.firstName : null,
      lastName: localUpdatedFormValues.lastName ? localUpdatedFormValues.lastName : null,
      accountHolderFN: localUpdatedFormValues.acctHolderFn ? localUpdatedFormValues.acctHolderFn : null,
      accountHolderLN: localUpdatedFormValues.acctHolderLn ? localUpdatedFormValues.acctHolderLn : null
    };

    const defaultObj: any = {
      orgId: this.serviceOrgId       
    };

    if (this.filterClicked) {
      this.sharedService.getEmployeeList(param, filterObj, null, null).subscribe((response) => {
        this.setEmployeeAccntListData(response, initialLoad);
      });
    } else {
      this.sharedService.getEmployeeList(param, null, null, null).subscribe((response) => {
        this.setEmployeeAccntListData(response, initialLoad);
      });
    }
  }

  setEmployeeAccntListData(response, initialLoad) {
    this.employees = response.data ? response.data.content : response.data;
    if (this.editGroupData && this.editGroupData.oldRuleIdForCopy !== '' && this.editGroupData.mode === 'Copy') {
      this.editGroupData.id = this.editGroupData.oldRuleIdForCopy;
    }

    if (response.data) {
      this.totalRecords = response.data.totalElements;
      this.sharedService.totalEmployeeRecords.allEmployeeRecords = !this.filterClicked ? response.data.totalElements : this.sharedService.totalEmployeeRecords.allEmployeeRecords;
      this.unModifiedRecords = this.employees;
      if (this.empAccformData && initialLoad && (this.assignFormData.employeeAccounts.length > 0 || (this.moduleType === 'Rules' && this.assignFormData.status === 'DRAFT'))) {
        this.subAccntNumbers = this.assignFormData.subAccntNumbers;
        this.empIds = this.assignFormData.empIds;
        if ((this.moduleType === 'EmployeeGroup' && this.editGroupData.mode === 'EDIT') || this.moduleType === 'Rules') {
          this.assignFormData.empIds = [];
          this.assignFormData.subAccntNumbers = [];
        }
      }
      if ((this.moduleType === 'EmployeeGroup' && this.editGroupData.mode === 'EDIT')) {
        this.addRemoveExistingData();
      }
      this.populateData();
    }
    this.selectedArr = [...this.selectedArr];
    this.selectedEmployeeAccounts.emit(this.selectedArr);
    if (this.selectedNoOfRecords) {
      this.setEmpCount();
      this.selectedNoOfRecords = false;
    } else {
      this.setEmpCount();
    }
  }

  addRemoveExistingData() {
    const editGroupData = (this.moduleType === 'EmployeeGroup') ? this.editGroupData.employeeGroupAssignInfo : this.editGroupData;
    if ((this.selectedNewRecords.length > 0 || this.alreadySelectedRemoval.length > 0 || this.subAccntNewlySelected.length > 0 || this.removedExistingEmpId.length > 0 ||
      (editGroupData && editGroupData.newSelectedRecords && editGroupData.newSelectedRecords.length > 0) ||
      (editGroupData && editGroupData.subAccntNewlySelected && editGroupData.subAccntNewlySelected.length > 0) ||
      (editGroupData && editGroupData.removedExistingEmpId && editGroupData.removedExistingEmpId.length > 0) ||
      (editGroupData && editGroupData.removedExistingSelected && editGroupData.removedExistingSelected.length > 0)) &&
      (this.moduleType === 'EmployeeGroup' && this.editGroupData.mode === 'EDIT') || this.moduleType === 'Rules') {

        const formSelectedRecords = (editGroupData.newSelectedRecords && editGroupData.newSelectedRecords.length > 0) ? [...editGroupData.newSelectedRecords] : [];
        const formRemovedRecords = (editGroupData.removedExistingSelected && editGroupData.removedExistingSelected.length > 0) ? [...editGroupData.removedExistingSelected] : [];

        const localSelectedArr = (this.selectedNewRecords.length > 0) ? [...this.selectedNewRecords] : [...formSelectedRecords];
        const localRemovedArr = (this.alreadySelectedRemoval.length > 0) ? [...this.alreadySelectedRemoval] : [...formRemovedRecords];
        this.selectedNewRecords = (this.selectedNewRecords && this.selectedNewRecords.length > 0) ? this.selectedNewRecords : [...formSelectedRecords];
        this.alreadySelectedRemoval = (this.alreadySelectedRemoval && this.alreadySelectedRemoval.length > 0) ? this.alreadySelectedRemoval : [...formRemovedRecords];
        this.updateSelectedArray(localSelectedArr, localRemovedArr);
        const localFormData = {
          newSelectedRecords: this.selectedNewRecords,
          removedExistingSelected: this.alreadySelectedRemoval,
          subAccntNewlySelected: this.subAccntNewlySelected,
          removedExistingEmpId: this.removedExistingEmpId,
        };
        this.newlySelectedEmployeeAccounts.emit(localFormData);
    }
  }

  updateSelectedArray(newlySelected, removedExisting) {
    if (newlySelected && newlySelected.length) {
      newlySelected.forEach(newEle => {
        const selIdx = this.selectedArr.findIndex(selEle => selEle.empId === newEle.empId && selEle.subAcctNum === newEle.subAcctNum);
        if(selIdx === -1 ) {
          this.selectedArr.push(newEle);
        }
      });
    }

    if (removedExisting && removedExisting.length) {
      removedExisting.forEach(remEle => {
        const selIdx = this.selectedArr.findIndex(selEle => selEle.empId === remEle.empId && selEle.subAcctNum === remEle.subAcctNum);
        if(selIdx !== -1 ) {
          this.selectedArr.splice(selIdx, 1);
        }
      });
    }
  }

  setEmpCount() {
    this.getSelectedAccCount(this.selectedArr);
  }

  populateData() {
    if (this.moduleType === 'Rules' && this.assignFormData.status === 'DRAFT' && this.selectedArr.length === 0) {
      const selectedArr = [];
      this.employees.forEach(empRecord => {
        if ((empRecord.ruleIds && empRecord.ruleIds.indexOf(this.editGroupData.id) !== -1)) {
          empRecord.parent = false;
          empRecord.child = true;
          selectedArr.push(empRecord);
        }

        if ((empRecord.empLevelRuleIds && empRecord.empLevelRuleIds.indexOf(this.editGroupData.id) !== -1)) {
          const empIdx = selectedArr.findIndex(sel => (empRecord.subAcctNum === sel.subAcctNum && empRecord.empId === sel.empId));
          if (empIdx === -1) {
            empRecord['parent'] = empRecord['child'] = empRecord['selectedEmployee'] = true;
            selectedArr.push(empRecord);
          } else {
            selectedArr[empIdx]['parent'] = selectedArr[empIdx]['child'] = true;
          }
        }
      });
      this.selectedArr = selectedArr || [];
      this.assignFormData.employeeAccounts = this.selectedArr;
    }
    this.employees.map(ele => {
      const selEmpIdx = this.selectedArr.findIndex(selEle => (ele.empId === selEle.empId && ele.subAcctNum === selEle.subAcctNum));
      const newSelEmpIdx = this.selectedNewRecords.findIndex(newEle => (ele.empId === newEle.empId && ele.subAcctNum === newEle.subAcctNum));
      const remSelEmpIdx = this.alreadySelectedRemoval.findIndex(remEle => (ele.empId === remEle.empId && ele.subAcctNum === remEle.subAcctNum));
      if ((this.moduleType === 'EmployeeGroup'  && this.editGroupData.mode === 'EDIT') || this.moduleType === 'Rules') {
        if (selEmpIdx !== -1 && this.selectedArr[selEmpIdx].hasOwnProperty('parent')) {
          ele['parent'] = this.selectedArr[selEmpIdx]['parent'] = this.selectedArr[selEmpIdx]['parent'];
          ele['child'] = this.selectedArr[selEmpIdx]['child'] = this.selectedArr[selEmpIdx]['child'];
          this.selectedArr[selEmpIdx] = ele;
          this.selectedArr[selEmpIdx] = ele;          
        } else if (selEmpIdx !== -1) {
          ele['parent'] = this.checkParentLevelEmp(ele);
          ele['child'] = (this.checkParentLevelEmp(ele) || this.checkAccountLevelEmp(ele));
          this.selectedArr[selEmpIdx] = ele;
        } else if (newSelEmpIdx !== -1) {
          ele['parent'] = this.selectedNewRecords[newSelEmpIdx]['parent'] = this.selectedNewRecords[newSelEmpIdx]['parent'];
          ele['child'] = this.selectedNewRecords[newSelEmpIdx]['child'] = this.selectedNewRecords[newSelEmpIdx]['child'];
          this.selectedNewRecords[newSelEmpIdx] = ele;
        } else if (remSelEmpIdx !== -1) {
          ele['parent'] = false;
          ele['child'] = false;
        } else if ((this.moduleType === 'Rules' && this.initialLoadData) || this.moduleType === 'EmployeeGroup') {
          ele['parent'] = this.checkParentLevelEmp(ele);
          ele['child'] = (this.checkParentLevelEmp(ele) || this.checkAccountLevelEmp(ele));
          ele['parent'] || ele['child'] ? this.selectedArr.push(ele) : '';
        }
      } else {
        ele['parent'] = (this.sharedService.empGrpStep2InitialEmpData.length && selEmpIdx !== -1) ? this.selectedArr[selEmpIdx]['parent'] : (selEmpIdx !== -1 && this.selectedArr[selEmpIdx]['parent']) ? true : false;
        ele['child'] = (selEmpIdx !== -1 && this.selectedArr[selEmpIdx]['child']) ? true : false;
      }
      return ele;
    });
    return this.employees;
  }

  checkNewlyAndRemovedData(ele) {
    if (this.editGroupData.mode === 'EDIT' && this.moduleType === 'EmployeeGroup') {
      if (this.selectedNewRecords && this.selectedNewRecords.length) {
        const selNewEmpIdx = this.selectedNewRecords.findIndex(selEle => (ele.empId === selEle.empId && ele.subAcctNum === selEle.subAcctNum));

      }
      if (this.alreadySelectedRemoval && this.alreadySelectedRemoval.length) {

      }
    }
  }

  checkParentLevelEmp(empRecord) {
    return (this.moduleType === 'EmployeeGroup' && empRecord.empLevelGroupIds && empRecord.empLevelGroupIds.indexOf(this.editGroupData.id) !== -1) ||
      (this.moduleType === 'Rules' && empRecord.empLevelRuleIds && empRecord.empLevelRuleIds.indexOf(this.editGroupData.id) !== -1);
  }

  checkAccountLevelEmp(empRecord) {
    return (this.moduleType === 'EmployeeGroup' && empRecord.groupIds && empRecord.groupIds.indexOf(this.editGroupData.id) !== -1) ||
      (this.moduleType === 'Rules' && empRecord.ruleIds && empRecord.ruleIds.indexOf(this.editGroupData.id) !== -1);
  }

  receiveDataFromChild(e) {
    this.pageNo = 1;
    this.noOfRecords = Number(e);
    this.selectedNoOfRecords = true;
    this.sharedService.clearSuccessMsgs(this.messageService, true);
    this.getEmpAccntResponse(this.paramObj(this.pageNo, this.noOfRecords, this.sortField), false);
  }

  customSort(event) {
    if (event.multiSortMeta) {
      this.paginator.changePage(0);
      if (this.sortField !== this.customSortService.customSort(event)) {
        this.sortField = this.customSortService.customSort(event);
        const noOfRecords = this.noOfRecords;
        this.isSortingChanged = (JSON.stringify(event.multiSortMeta) === JSON.stringify([this.defaultSortDataField])) ? false : true;
        if (this.sharedService.empListMultiSortMeta !== event.multiSortMeta) {
          this.sharedService.empListMultiSortMeta = event.multiSortMeta;
          if (!this.initialLoad) {
            this.clearSuccessAndErrorMessages();
          } else {
            this.initialLoad = false;
          }
          if ((this.moduleType === 'EmployeeGroup' && this.editGroupData.mode !== 'EDIT' ) || (this.moduleType === 'TwoStepRule' )) {
            this.getEmpAccntResponse(this.paramObj(this.pageNo, noOfRecords, this.sortField), true);
          } else {
            this.getEmpAccntResponse(this.paramObj(this.pageNo, noOfRecords, this.sortField), false);
          }
        }
      }
    }
  }

  paginate(e) {
    if (this.pageNo !== e.page + 1) {
      this.pageNo = e.page + 1;
      this.sortField = this.param.sortField;
      const noOfRecords = this.param.pageSize;
      this.employeeSelection = '';
      this.clearSuccessAndErrorMessages();
      this.getEmpAccntResponse(this.paramObj(this.pageNo, noOfRecords, this.sortField), false);
    }
  }

  getDatafromParent(data, type, load) {
    if (type) {
      this.getCompanyGrpEmpCount = data.companyGroupEmp;
      this.getCompanyGrpAccCount = data.companyGroupAcc;
    } else {
      this.chkCompanyGrp = data;
      if (data) {
        if (data.length > 0 && data[0].type === 'ORG') {
          this.validateCompanyGrp = data[0].type;
          this.selectedArr = [];
          this.empIds = [];
          this.subAccntNumbers = [];
          this.checkParentEmpId('remove');
        } else {
          if (!load) {
            this.getSelectedCount(this.selectedArr);
          }
        }
      }
    }
    this.validateCompanyGrp = data.length > 0 ? data[0].type : '';
  }

  getSelectedAccCount(selectedArr) {
    this.parent = 0;
    this.child = 0;
    if (selectedArr) {
      this.countForSelectedArr(selectedArr);
    }
    this.empAccformData = selectedArr;
    if (this.moduleType === 'Rules') {
      this.selectedArr = selectedArr;
    }
    const emitData = {
      empIds: this.empIds,
      subAccntNumbers: this.subAccntNumbers,
      selectedArr: (selectedArr),
    };
    this.employeeAccounts.emit(emitData);
    /*Initial empty empId parent checkbox Selection Functionality */
  }

  countForSelectedArr(arr) {
    const localEmpId = [];
    let partialSelected;
    arr.forEach(data => {
        if (data) {
          /* Edit Scenario partialSelected will be undefined so setting it to true */
          if (partialSelected && data.empId !== null && localEmpId.indexOf(data.empId) === -1) {
            this.parent++;
            localEmpId.push(data.empId);
          } else if (data.empId && data.empId.indexOf('null') !== -1) {
            this.parent++;
          }
          this.child++;
        } else {
          if (data.empId !== null && !partialSelected) {
            this.parent++;
          }
        }
      });
  }

  getSelectedEmpAccounts(e, selectedVal, el) {
    if (e === 'parent') {
      selectedVal['parent'] = selectedVal[e];
      selectedVal['child'] = selectedVal[e];
    } else if (e === 'child') {
      selectedVal['child'] = selectedVal[e];
      !selectedVal[e] ? selectedVal['parent'] = selectedVal[e] : selectedVal['parent'] = selectedVal['parent'];
    }
    const empIdx = this.selectedArr.findIndex(ele => (ele.empId === selectedVal.empId && ele.subAcctNum === selectedVal.subAcctNum));
    if (selectedVal[e]) {
      selectedVal['selectedEmployee'] = true;
      if (empIdx !== -1) {
        this.selectedArr[empIdx] = selectedVal;
      } else {
        this.selectedArr.push(selectedVal);
      }
    } else {
      selectedVal['selectedEmployee'] = false;
      empIdx !== -1 ? this.selectedArr.splice(empIdx, 1) : '';
    }
    
    if (this.editGroupData && this.editGroupData.mode === 'EDIT' && this.moduleType === 'EmployeeGroup') {
      this.emitNewlySelectedRemovedRecords(el, selectedVal, e);
    }
    this.selectedEmployeeAccounts.emit(this.selectedArr);
    this.rowSelected.emit();
  }

  emitNewlySelectedRemovedRecords(el, selectedVal, e) {
    const newSelIdx = this.selectedNewRecords.findIndex(newEle => selectedVal.empId === newEle.empId && selectedVal.subAcctNum === newEle.subAcctNum);
    const alreadyPresentSelection = (this.moduleType === 'EmployeeGroup' && selectedVal.groupIds && selectedVal.groupIds.length > 0 && selectedVal.groupIds.indexOf(this.editGroupData.id) !== -1);
    if (selectedVal[e] && newSelIdx === -1 && !alreadyPresentSelection) {
      this.selectedNewRecords.push(selectedVal);
    } else if (selectedVal[e] && newSelIdx !== -1) {
      this.selectedNewRecords[newSelIdx] = selectedVal;
    } else if (!selectedVal[e] && newSelIdx !== -1) {
      this.selectedNewRecords.splice(newSelIdx, 1);;
    }

    const remSelIdx = this.alreadySelectedRemoval.findIndex(remEle => selectedVal.empId === remEle.empId && selectedVal.subAcctNum === remEle.subAcctNum);
    if (!selectedVal[e] && remSelIdx === -1 && alreadyPresentSelection) {
      this.alreadySelectedRemoval.push(selectedVal);
    } else if (selectedVal[e] && remSelIdx !== -1) {
      this.alreadySelectedRemoval.splice(remSelIdx, 1);
    }
    const localFormData = {
      newSelectedRecords: this.selectedNewRecords,
      removedExistingSelected: this.alreadySelectedRemoval,
      subAccntNewlySelected: this.subAccntNewlySelected,
      removedExistingEmpId: this.removedExistingEmpId,
    };
    this.newlySelectedEmployeeAccounts.emit(localFormData);
  }

  /* Handling the parent empty empId checkbox checked while loading */
  checkParentEmpId(data) {
    const wholeArray = this.el.nativeElement.querySelectorAll('tr');
    wholeArray.forEach(element => {
      if (element.querySelector('.p-highlight') && element.querySelector('.check-disabled')) {
        const entireCheckedArray = element.querySelector('.p-checkbox-icon');
        data ? entireCheckedArray.classList.remove('pi-check') : entireCheckedArray.classList.add('pi-check');
      }
    });
    this.sharedService.removeEmptyCheckbox(this.el, 'accounts');
  }

  getSelectedCount(selectedArr) {
    this.parent = 0;
    this.child = 0;
    if (selectedArr) {
      this.countForSelectedArr(selectedArr);
    }
    const emitData = {
      empIds: this.empIds,
      subAccntNumbers: this.subAccntNumbers,
      selectedArr: this.selectedArr,
    };
    this.employeeAccounts.emit(emitData);
  }

  onChange() {
    this.empAccountForm.get('acctNum').valueChanges.subscribe(value => {
      /* check Account Number contains 8 digits */
      this.isAccNumInvalid = this.sharedService.isAccountNumInvalid = this.accountNumberValidationService.validateAccountNumber(value);
      this.sharedService.setErrorMessage(this.isAccNumInvalid, this.errMsg);
    });

    /* Validate the field and set the error message */
    this.empAccountForm.get('empId').valueChanges.subscribe(value => {
      this.empAccountForm.get('empId').setValue(value ? value.toUpperCase() : '', { emitEvent: false });
      this.filterBtnDisableForEmpId = this.sharedService.validationFields.filterBtnDisableForEmpId = this.sharedService.isValueLengthGreaterThan2(value);
      this.invalidEmpId = this.sharedService.validationFields.invalidEmpId = this.sharedService.isValidOrInvalid(value, 'empId', this.empAccountForm);
      this.sharedService.setErrorMessage(this.isAccNumInvalid, this.errMsg);
    });

    this.empAccountForm.get('firstName').valueChanges.subscribe(value => {
      this.empAccountForm.get('firstName').setValue(value ? value.toUpperCase() : '', { emitEvent: false });
      this.filterBtnDisableForFN = this.sharedService.validationFields.filterBtnDisableForFN = this.sharedService.isValueLengthGreaterThan2(value);
      this.invalidFN = this.sharedService.validationFields.invalidFN = this.sharedService.isValidOrInvalid(value, 'firstName', this.empAccountForm);
      this.sharedService.setErrorMessage(this.isAccNumInvalid, this.errMsg);
    });

    this.empAccountForm.get('lastName').valueChanges.subscribe(value => {
      this.empAccountForm.get('lastName').setValue(value ? value.toUpperCase() : '', { emitEvent: false });
      this.filterBtnDisableForLN = this.sharedService.validationFields.filterBtnDisableForLN = this.sharedService.isValueLengthGreaterThan2(value);
      this.invalidLN = this.sharedService.validationFields.invalidLN = this.sharedService.isValidOrInvalid(value, 'lastName', this.empAccountForm);
      this.sharedService.setErrorMessage(this.isAccNumInvalid, this.errMsg);
    });

    this.empAccountForm.get('acctHolderFn').valueChanges.subscribe(value => {
      this.empAccountForm.get('acctHolderFn').setValue(value ? value.toUpperCase() : '', { emitEvent: false });
      this.filterBtnDisableForacctHolderFN = this.sharedService.validationFields.filterBtnDisableForacctHolderFN = this.sharedService.isValueLengthGreaterThan2(value);
      this.invalidacctHolderFN = this.sharedService.validationFields.invalidacctHolderFN = this.sharedService.isValidOrInvalid(value, 'acctHolderFn', this.empAccountForm);
      this.sharedService.setErrorMessage(this.isAccNumInvalid, this.errMsg);
    });

    this.empAccountForm.get('acctHolderLn').valueChanges.subscribe(value => {
      this.empAccountForm.get('acctHolderLn').setValue(value ? value.toUpperCase() : '', { emitEvent: false });
      this.filterBtnDisableForacctHolderLN = this.sharedService.validationFields.filterBtnDisableForacctHolderLN = this.sharedService.isValueLengthGreaterThan2(value);
      this.invalidacctHolderLN = this.sharedService.validationFields.invalidacctHolderLN = this.sharedService.isValidOrInvalid(value, 'acctHolderLn', this.empAccountForm);
      this.sharedService.setErrorMessage(this.isAccNumInvalid, this.errMsg);
    });
  }

  toggleFunction() {
    this.toggle = !this.toggle;
  }

  clickedClearButton(resetSortFromUI?) {
    this.resetForm(resetSortFromUI);
    this.pageNo = 1;
    this.sortField = this.param.sortField;
    const noOfRecords = this.noOfRecords;
    if (!resetSortFromUI) {
      this.getEmpAccntResponse(this.paramObj(this.pageNo, noOfRecords, this.sortField), false);
    }
  }

  resetForm(resetSortFromUI?) {
    if (resetSortFromUI) {
      this.multiSortMeta = [this.defaultSortDataField];
    }
    this.empAccountForm.reset();
    this.paginator.changePage(0);
    this.errMsg = [];
    this.filterClicked = false;
    this.isAccNumInvalid = this.sharedService.isAccountNumInvalid = false;
    this.sharedService.setErrorMessage(this.isAccNumInvalid, this.errMsg);
  }

  applyFilter() {
    this.paginator.changePage(0);
    this.filterClicked = true;
    this.initialLoadData = false;
    this.pageNo = 1;
    this.sortField = this.param.sortField;
    const noOfRecords = this.noOfRecords;
    this.clearSuccessAndErrorMessages();
    this.getEmpAccntResponse(this.paramObj(this.pageNo, noOfRecords, this.sortField), false);
  }

  // Clear success & Error msg only. leave the warning msgs
  clearSuccessAndErrorMessages() {
    this.sharedService.clearSuccessMsgs(this.messageService, true);
    this.sharedService.clearErrorMessage();
  }
}
