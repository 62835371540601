<ems-table-record-count class="tbl-rec-count" [showRecords]="tableCountData?.paginationCount && tableCountData?.paginationCount > 15" [count]="tableCountData.totalRecords" [showTotalForInstrumentGrps]="tableCountData.totalRecords"
                        (emitter)="receiveDataFromChild($event);paginator.changePage(0);"></ems-table-record-count>

<ems-view-history #viewHistory [historyId]="masterGrpHistoryRecord.historyId" [getOrgId]="0"
  [ruleSubType]="masterGrpHistoryRecord.masterGrpSubType" [historyTitle]="masterGrpHistoryRecord.masterGrpName"
  [historyData]="masterGrpHistoryRecord.masterGrpHistory" [totalElements]="masterGrpHistoryRecord.historyRecords"
  (paginateHistoryList)="getHistoryPagination(masterGrpHistoryRecord.historyId, $event)"></ems-view-history>

<form [formGroup]="masterGroupForm">
  <p-table [columns]="masterGroupsTableCol" [value]="masterAcctList" [(selection)]="selectedArr" [customSort]="true" dataKey="id" [lazy]="true"
  metaKeySelection="true" (onLazyLoad)="customSort($event)" [multiSortMeta]="multiSortMeta" sortMode="multiple" #empGrps class="common-data-table manage-client-list-table">

  <ng-template pTemplate="header" let-columns>
    <!-- Column header section -->
    <tr class="filter-header-input">
      <th class="table-checkbox-selection filter-icon">
        <a (click)="toggleFunction()" class="cursor">
          <em class="pi pi-filter"></em>
        </a>
      </th>
      <th class="table-checkbox-selection" *ngIf="showMGPSelection">
        <p-tableHeaderCheckbox class="emtr-checkbox sdps-p-right_x-small" (click)="grpSelected('',$event)"></p-tableHeaderCheckbox>
      </th>
      <ng-container *ngFor="let col of columns">
        <th class="word-break" [pSortableColumn]="col.field" *ngIf="showActionBtn ? (col.field !== 'actions' || (col.field === 'actions' && toggle)) : col.field === 'actions' || col.field === 'noOfSubAccounts' || col.field === 'noOfRules' || col.field==='updTs' || col.field === 'name' || col.field === 'description'"
          [pSortableColumnDisabled]="(col.field === 'noOfRules' || col.field === 'noOfSubAccounts' || col.field === 'actions')"
          [ngClass]="[ col.header === 'Name' || col.header === 'Description' ? 'w-20-per':'' , col.header === 'Last Updated' || col.header === 'No. of Accounts' || col.header === 'No. of Rules Assigned' ? 'w-15-per':'', (tableCountData.totalRecords && tableCountData.totalRecords > 1 || filterClicked) ? 'pointer-events-auto':'pointer-events-none']">
          {{ !(showActionBtn && col.field === 'actions' && toggle) ? col.header : ''}}
          <p-sortIcon [field]="col.field"
            *ngIf="(col.field !== 'noOfSubAccounts' && col.field !== 'noOfRules' && col.field !== 'actions') && (tableCountData.totalRecords && tableCountData.totalRecords > 1 || filterClicked) "></p-sortIcon>
        </th>
      </ng-container>
    </tr>
    <tr *ngIf="toggle" class="filtered-row-input">
      <th></th>
      <th *ngIf="showMGPSelection"></th>

    <th class="sdps-p-left_small" *ngFor="let col of columns ;let i = index;">
      <input pInputText formControlName="groupName" class="w-100-per" type="text" name="name" [maxLength]="35" [ngClass]="{'invalidField': filterBtnDisableForName}"
      *ngIf="col.field === 'name'" (keydown.enter)="!( masterGroupForm.invalid || filterBtnDisableForName) ? applyFilter(): ''" onkeydown="return (event.keyCode !== 13);">
      <input pInputText formControlName="groupDescription" class="w-100-per" type="text" name="description" [maxLength]="35"
      *ngIf="col.field === 'description'" (keydown.enter)="!( masterGroupForm.invalid || filterBtnDisableForName)? applyFilter(): ''" onkeydown="return (event.keyCode !== 13);">
      <div *ngIf="col.field === 'updTs'">
        <ems-date-range-filter #range (submittedDate)="getSelectedDate($event);"></ems-date-range-filter>
      </div>
      <div *ngIf="col.field === 'actions'" class="w-200-px">
        <button class="buttons button-filter" [disabled]="!masterGroupForm.dirty && !isSortingChanged"
          [innerHTML]="getSharedConstant.filterButton.clearAllButton"
          (click)="filterClicked? clickedClearButton(isSortingChanged) : resetForm(isSortingChanged); clearSuccessAndErrorMessages();"></button>
        <button class="buttons button-save button-filter" (click)="applyFilter()"
          [disabled]="filterBtnDisableForName || masterGroupForm.invalid"
          [innerHTML]="getSharedConstant.filterButton.applyFilterButton"></button>
      </div>
    </th>
    </tr>

    <tr *ngIf="!showMGPSelection && toggle && showActionItems" class="filter-row-input">
      <th></th>
      <th *ngFor="let col of groupSubHeader">
        {{col.header}}
      </th>
    </tr>

    <tr *ngIf="!showMGPSelection && toggle && showActionItems" class="filtered-row-input">
      <th></th>
      <th class="sdps-p-left_small" *ngFor="let col of groupSubHeader">
        <div class="timePickerForm sdps-p-bottom_medium" *ngIf="col.field === 'showRecords'">
          <p-dropdown class="request-list-header-dropdown" appendTo="body" optionLabel="label" formControlName="{{col.field}}" [options]="getSharedConstant.showRecords">
          </p-dropdown>
          <!-- editableTimepicker -->
        </div>
      </th>
    </tr>
  </ng-template>

    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
        <td [attr.colspan]="columns.length + 3" class="sdps-text-center sdps-font-weight-bold"
            [innerHTML]="getSharedConstant.noRecords"></td>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr [pSelectableRow]="rowData">
        <td class="table-checkbox-selection filter-icon"></td>
        <td *ngIf="showMGPSelection">
          <p-tableCheckbox class="emtr-checkbox sdps-p-right_x-small" [value]="rowData" (click)="grpSelected(rowData,$event)"></p-tableCheckbox>
        </td>
        <ng-container *ngFor="let col of columns">
          <td *ngIf="col.field !== 'actions'">
          <span
            *ngIf="col.field !== 'updTs' && col.field !== 'noOfRules' && col.field !== 'name' && col.field !== 'noOfEmployees' && col.field !== 'noOfSubAccounts'"
            [innerHTML]='rowData[col.field]'></span>
            <span *ngIf="col.field ==='noOfSubAccounts' && rowData.assignStatus !== 'Failed'" [innerHTML]='(processID.indexOf(rowData.id) !== -1) ? ((showUpdatedCount && showUpdatedCount.data.noOfSubAccounts) ? showUpdatedCount.data.noOfSubAccounts : " ") : rowData[col.field]'></span>
            <span *ngIf="col.field ==='noOfRules' && rowData.assignStatus !== 'Failed'" [innerHTML]='(processID.indexOf(rowData.id) !== -1) ? ((showUpdatedCount && showUpdatedCount.data.noOfRules) ? showUpdatedCount.data.noOfRules : " ") : rowData[col.field]'></span>

            <div *ngIf="showActionBtn ? !showActionBtn : col.field ==='noOfRules' || col.field ==='noOfSubAccounts'">
              <span *ngIf="processID.indexOf(rowData.id) !== -1  && showSpinnerFlag[rowData.id]"><p-progressSpinner class="spinnerStyle"></p-progressSpinner></span>
              <span *ngIf="processID.indexOf(rowData.id) !== -1  && showErrorFlag[rowData.id]">{{rowData[col.field]}} <em class="sch-exclamation-octagon" pTooltip="{{getSharedConstant[getSharedConstant.manageMasterGroups.groupTypeMGP].toolTipMsgexclamatoryIcon}}"
                                                                                                                          tooltipPosition="left"></em></span>
              <span *ngIf="rowData.assignStatus === 'Failed'">{{rowData[col.field]}} <em class="sch-exclamation-octagon" [pTooltip]="getSharedConstant[getSharedConstant.manageMasterGroups.groupTypeMGP].toolTipMsgexclamatoryIcon"
                                                                                         tooltipPosition="left"></em></span>
            </div>

            <span *ngIf="col.field==='updTs'"
                  [innerHTML]="(rowData[col.field]) ? moment(rowData[col.field]).format('MM/DD/YYYY') : ''"></span>
            <span *ngIf="col.field==='name'&& !showMGPSelection" [ngClass]="{'masterAccGrpLinks cursor': rowData['type'] !== 'ORG'}"
                  (click)="editMasterGrp(rowData)" [innerHTML]='rowData[col.field]'></span>
            <span *ngIf="col.field==='name' && showMGPSelection" [innerHTML]='rowData[col.field]'></span>
          </td>
          <td *ngIf="showMGPSelection && showActionBtn && col.field === 'actions' && toggle"></td>
        </ng-container>
        <td *ngIf="!showMGPSelection">
          <div class="action-menu-dropdown" *ngIf="rowData['type'] !== 'ORG'">
            <button type="button" class="cursor sdps-p-around_xx-small"
                    (click)="showActionMenu=true; empId=rowData['id']; formData();"
                    [ngClass]="{'active-menu':(empId===rowData['id'])}">
              <em class="pi pi-ellipsis-h sdps-p-around_xx-small"></em>
            </button>
            <p-menu [model]="actionMenu" *ngIf="empId===rowData['id']" class="menu-dropdown"
                    (click)="onActionMenuClick($event, rowData)"></p-menu>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</form>
<p-paginator class="common-pagination" [rows]="this.param.pageSize" [alwaysShow]="tableCountData?.paginationCount && tableCountData?.paginationCount > 15 ? true : false"
[totalRecords]="tableCountData.paginationCount" (onPageChange)="paginate($event)" #p></p-paginator>
