<ems-service-error *ngIf="sharedService.errorMessage | async"></ems-service-error>

<sdps-notification type="error" class="sdps-m-top_none" *ngIf="filterBtnDisableForsubmittedBy">
  <div class="sdps-text-l-body">
    <p class="sdps-notification__text" [innerHTML]="addLinkConstant.pleaseCorrectFollowingError"></p>
    <ul class="sdps-navigation--horizontal__list error-list">
      <li *ngIf="filterBtnDisableForsubmittedBy"
          [innerHTML]="getSharedConstant.textFieldErrorMsg.minCharLimitErrorMsg">
      </li>
    </ul>
  </div>
</sdps-notification>

<div>
  <h2 class="page-title sdps-m-bottom_large sdps-display-inline-block"
      [innerHTML]="getConstant.reports.reportsListPage.reportsTitls"></h2>

  <span class="sdps-m-horizontal_large sdps-m-bottom_medium sdps-display-inline-block client-selection-indicator"
        [ngClass]="this.externalAccess ? 'external-access' : (addlinkService.clientInformation | async)?.businessUnit | lowercase"
        [innerHTML]="(addlinkService.clientInformation | async)?.k4ClientName">
  </span>

  <!-- Adding Refresh Button in Reports Landing Page -->
  <div>
    <div class="sdps-display-inline-block sdps-is-absolute sdps-m-top_x-small sdps-m-bottom_x-small sdps-p-left_small report-align">
      <div class="sdps-display-inline-block take-action-container sdps-p-right_small">
        <p-dropdown #dropdown [placeholder]="" [(ngModel)]="selectedAction" [options]="takeActionMenuList" [disabled]="!this.sharedService.checkEntitlements(this.entitlementConst.navUUID.emtrAccess.emtrAppAccess.uuid, '', '')">
          <ng-template let-list pTemplate="list">
            <div class="menu-item" (click)="showSelectedReportTypeReportList(list.value)">
              <span class="menu-list-name">{{list.value}}</span>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
      <button type="button" class="buttons" (click)="sharedService.gutTracking('adobe-lc_refresh|fireonce'); getReportsList(); sharedService.clearErrorMessage();">
        {{getConstant.reports.presetReports.refreshTxt}}
        <em class="sdps-p-left_xxx-small sdps-font-weight-bold sdps-text-small sch-refresh cursor"
            (click)="getReportsList(); sharedService.clearErrorMessage();" title="refresh"></em></button>
    </div>
  </div>

  <ems-table-record-count class="tbl-rec-count" [count]="totalReports" [showTotal]="totalReports"
                          (emitter)="pageObject.pageNo = 1;pageObject.pageSize = $event;applyFilter();"
                          [showHeaderIcons]="true" [showRecords]="paginationCount && paginationCount > 15"></ems-table-record-count>
  <form [formGroup]="reportListForm" class="reportListMenu">

  <p-table [columns]="reportListTableCols" [value]="reportsList" metaKeySelection="true" [customSort]="true"
           [lazy]="true" (onLazyLoad)="customSort($event)" [multiSortMeta]="multiSortMeta" sortMode="multiple"
           class="common-data-table manage-client-list-table custom-table-filter">
    <ng-template pTemplate="header" let-columns>
      <tr class="filter-header-input">
        <th class="table-checkbox-selection filter-icon">
          <a (click)="toggleFunction()" class="cursor">
            <em class="pi pi-filter"></em>
          </a>
        </th>
        <th *ngFor="let col of columns" [pSortableColumn]="col.field" [pSortableColumnDisabled]="col.field !== 'reportId'" (click)="sharedService.clearErrorMessage()"
            [ngClass]="[(col.field === 'reportId' ? 'w-10-per' : ''), (col.field === 'reportType' ? 'w-20-per' : ''), (col.field === 'action' ? 'w-10-per' : ''), ((paginationCount && paginationCount > 1 || filterClicked) ? 'pointer-events-auto' : 'pointer-events-none')]">
          {{col.header}}
          <p-sortIcon [field]="col.field" *ngIf="col.field ==='reportId' && (paginationCount && paginationCount > 1 || filterClicked)"></p-sortIcon>
        </th>
      </tr>

      <tr *ngIf="this.toggle" class="filtered-row-input">
        <th></th>
        <th *ngFor="let col of columns" class="sdps-p-left_small">
          <input pInputText formControlName="{{col.field}}" class="w-100-per" type="text" name="reportId" *ngIf="col.field === 'reportId'"
                 (keydown.enter)="applyFilter()" onkeypress="return (event.which >= 48 && event.which <= 57)">

          <input pInputText formControlName="{{col.field}}" class="w-100-per" type="text" name="submittedBy" (keydown.enter)="!filterBtnDisableForsubmittedBy ? applyFilter() : ''" onkeydown="return (event.keyCode !== 13);"
                 *ngIf="col.field === 'submittedBy'" [ngClass]="{'invalidField': filterBtnDisableForsubmittedBy, 'disabled': filterState === 'disable'}">

          <div class="timePickerForm report-type" *ngIf="col.enum === 'dropdown'">
            <p-dropdown appendTo="body" [options]="reportTypeDropdown" placeholder=" " formControlName="{{col.field}}"
              class="sdps-p-top_small editableTimepicker request-list-header-dropdown" optionLabel="label">
            </p-dropdown>
          </div>

          <div *ngIf="col.enum === 'multiselect'"
               class="col-2 emtr-dropdown timePickerForm request-list-filter request-list-multiselect-font-size request-list-filter-multiselect">
            <p-multiSelect appendTo="body" formControlName="{{col.field}}" [options]="getConstant.reports.reportsListPage.reportListFilter.status"
                           [filter]="true" [defaultLabel]="' '" [displaySelectedLabel]="true" [maxSelectedLabels]="1"
                           [showHeader]="true" [filterPlaceHolder]="'All'" [filter]="true">
            </p-multiSelect>
          </div>

          <div *ngIf="col.enum === 'dateRange'">
            <ems-date-range-filter #range [disableForm]="filterState === 'disable'" (submittedDate)="getSelectedDate($event);" [limitCustomRangeTo1Month]="true"></ems-date-range-filter>
          </div>

          <div *ngIf="col.field === 'action'" class="sdps-p-right_none word-keep-all display-flex">
            <button class="buttons button-filter" [innerHTML]="getConstant.uploadListPage.uploadsListFilter.clearButton"
                    [disabled]="!reportListForm.dirty && !isSortingChanged" (click)="isClearBtnClicked = true;(filterClicked)? clickedClearButton(isSortingChanged) : resetForm(isSortingChanged)"></button>
            <button class="buttons button-save button-filter" [disabled]= "filterBtnDisableForsubmittedBy" (click)="applyFilter()"
                    [innerHTML]="getConstant.uploadListPage.uploadsListFilter.applyButton"></button>
          </div>
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
        <td [attr.colspan]="columns.length + 1" class="sdps-text-center"
            [innerHTML]="getConstant.manageUserPage.noRecords"></td>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr [pSelectableRow]="rowData">
        <td class="table-checkbox-selection filter-icon"></td>
        <ng-container *ngFor="let col of columns">
        <td *ngIf="col.field !== 'action'" [ngClass]="[(col.field === 'reportId' ? 'w-10-per' : ''), (col.field === 'reportType' ? 'w-20-per' : '')]">
          <span *ngIf="col.field === 'reportId'" [innerHTML]="rowData[col.field]"></span>
          <span *ngIf="col.field === 'clientName'" [innerHTML]="rowData[col.field]"></span>
          <span *ngIf="col.field === 'reportType'">
          <a class="cursor" [innerHTML]="rowData[col.field]" (click) = "navigateToReports(rowData[col.field])"></a>
          </span>
          <span *ngIf="col.field === 'status'" class="word-keep-all align-item-center display-flex">
            <span class="status sdps-p-around-xx-large" *ngIf="rowData['status'] !== 'Processing'" ngClass="{{rowData[col.field] | lowercase}}">
            </span>
            <span class="status-word-wrap sdps-m-left_x-small" *ngIf="rowData['status'] !== 'Processing'" [innerHTML]="(rowData[col.field] === getConstant.uploadListPage.started || rowData[col.field] === getConstant.uploadListPage.received) ? getConstant.uploadListPage.processingStatus : rowData[col.field] | titlecase"></span>
            <!-- <p-progressBar [value]="rowData['progressValue']" *ngIf="rowData['status'] === 'Processing'" [ngClass]="{'custom-progressbar' : rowData['progressValue'] === 0}"></p-progressBar> -->
            <sdps-progress-indicator sdps-id="loader-determinate" *ngIf="rowData['status'] === 'Processing'" class="custom-progressbar"
                 [attr.helper-text]="rowData['progressValue'] + '%'" [value]="rowData['progressValue']" max="100"
                 sdps-aria-describedby="loading-zone" sr-value-suffix="percent"></sdps-progress-indicator>
          </span>
          <span *ngIf="col.field === 'submittedDate'" [innerHTML]="rowData[col.field]? moment(rowData[col.field]).format('MM/DD/YYYY'): ''"></span>
          <span *ngIf="col.field === 'submittedBy'" [innerHTML]="rowData[col.field]"></span>
        </td>
        </ng-container>
        <td>
          <div class="action-menu-dropdown">
            <button type="button" class="cursor sdps-p-around_xx-small"
                    (click)="showActionMenu=true; reportId=rowData['reportId']; formMenuItem(rowData);"
                    [ngClass]="{'active-menu':reportId===rowData['reportId']}">
              <em class="pi pi-ellipsis-h sdps-p-around_xx-small"></em>
            </button>
            <p-menu [model]="actionMenu"
                    *ngIf="reportId===rowData['reportId']"
                    class="menu-dropdown" (click)="onActionMenuClick($event, rowData)"></p-menu>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
  </form>
  <p-paginator #p *ngIf="paginationCount && paginationCount > 15" class="common-pagination" [rows]="pageObject.pageSize" [totalRecords]="paginationCount"
               (onPageChange)="paginate($event);"></p-paginator>
</div>

<app-report-details-modal #reportDetailsModal></app-report-details-modal>
