import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {EmtrService} from './emtr.service';
import {SharedConstant, SharedService} from '@ems/shared';
import {emtrConstant} from './constants/emtrConstants';
import {Subscription} from 'rxjs';

@Component({
  selector: 'tr-emtr',
  templateUrl: './emtr.component.html'
})
export class EmtrComponent implements OnInit, OnDestroy {

  envProp: any;
  rulesSubMenu: any;
  isModalOpened: any;
  clientInfo: any;
  sharedConstant = SharedConstant;
  businessUnit: any;
  getConstant = emtrConstant;
  public clientInfoSubscribe: Subscription;
  hasEmtrAppAcc = true;
  userPolicy: any;
  accessForRbsOnly = false;
  accessToDBSClient = false;
  accessToSPSClient = false;
  accessWithRuleAgentDBSClient = false;
  accessWithRuleAgentSPSClient = false;

  constructor(private route: ActivatedRoute, private emtrService: EmtrService, public sharedService: SharedService,
              @Inject('entitlementConst') public entitlementConst, public router: Router) {
    this.route.data.subscribe(data => {
      this.sharedService.clearErrorMessage();
      this.envProp = data.envProp;
      console.log(':::::::::::::::::::::::::  ENV PROP FROM SHELL::::::::::: EmtrComponent :::::::::');
      console.log(this.envProp);
      this.emtrService.setEnvPath(this.envProp);
    });

    this.emtrService.userPolicy.subscribe((policy) => {
      console.log('Current userPolicy in EMTR: ' + policy);
      console.log(policy);
    });
  }

  ngOnInit() {
    this.userPolicy = this.sharedService.getPolicyResponse('success');
    /* Navigate to respective page based on the selected client access */
    this.clientInfoSubscribe = this.sharedService.selectedClientInfo.subscribe(clientInfo => {
        this.clientInfo = clientInfo;
        this.sharedService.orgIdValue = clientInfo ? clientInfo.orgId : 0;
        this.sharedService.businessUnitVal = clientInfo ? clientInfo.businessUnit : '';
        this.hasEmtrAppAcc = this.sharedService.checkEntitlements(this.entitlementConst.navUUID.emtrAccess.emtrAppAccess.uuid, '', '');
        this.businessUnit = clientInfo ? clientInfo.businessUnit : '';
        this.setNavigation();
    });
    this.setNavigation();
    this.sharedService.isModalOpened.subscribe(value => {
      this.isModalOpened = value;
    });
  }

  setNavigation() {
    const getUUID = this.entitlementConst.navUUID;
    this.userPolicy = this.sharedService.getPolicyResponse('success');
              /* eslint-disable-next-line max-len */
              this.accessForRbsOnly = (this.userPolicy.data.userPolicy.buPolicy && this.userPolicy.data.userPolicy.buPolicy.length === 1 && this.userPolicy.data.userPolicy.buPolicy.map(x => x.businessUnit).indexOf('RBS') !== -1) || (this.userPolicy.data.userPolicy.buPolicy === null && this.userPolicy.data.clients && this.userPolicy.data.clients.every(x => x.businessUnit === 'RBS'));
              /* eslint-disable-next-line max-len */
              this.accessToDBSClient = (this.userPolicy.data.userPolicy.buPolicy && this.userPolicy.data.userPolicy.buPolicy.some(val => val.businessUnit === 'DBS')) || (this.userPolicy.data.userPolicy.buPolicy === null && this.userPolicy.data.clients && this.userPolicy.data.clients.some(x => x.businessUnit === 'DBS'));
              /* eslint-disable-next-line max-len */
              this.accessToSPSClient = (this.userPolicy.data.userPolicy.buPolicy && this.userPolicy.data.userPolicy.buPolicy.some(val => val.businessUnit === 'SPS')) || (this.userPolicy.data.userPolicy.buPolicy === null && this.userPolicy.data.clients && this.userPolicy.data.clients.some(x => x.businessUnit === 'SPS'));
              /* eslint-disable-next-line max-len */
              this.accessWithRuleAgentDBSClient = (this.userPolicy.data.userPolicy.buPolicy && this.userPolicy.data.userPolicy.buPolicy.some(val => val.businessUnit === 'DBS' && val.entitlementList.map(y => y.entitlementName).indexOf('RULES_SUMMARY') !== -1)) || (this.userPolicy.data.userPolicy.buPolicy === null && (this.userPolicy.data.userPolicy.clientPolicy.some(x => this.userPolicy.data.clients.some(y => x.clientId === y.clientId && y.businessUnit === 'DBS' && x.entitlementList.map(z => z.entitlementName).indexOf('RULES_SUMMARY') !== -1))));
              /* eslint-disable-next-line max-len */
              this.accessWithRuleAgentSPSClient = (this.userPolicy.data.userPolicy.buPolicy && this.userPolicy.data.userPolicy.buPolicy.some(val => val.businessUnit === 'SPS' && val.entitlementList.map(y => y.entitlementName).indexOf('RULES_SUMMARY') !== -1)) || (this.userPolicy.data.userPolicy.buPolicy === null && (this.userPolicy.data.userPolicy.clientPolicy.some(x => this.userPolicy.data.clients.some(y => x.clientId === y.clientId && y.businessUnit === 'SPS' && x.entitlementList.map(z => z.entitlementName).indexOf('RULES_SUMMARY') !== -1))));
    this.rulesSubMenu = [
      {
        id: 1,
        name: 'Schwab Trade Rules<sup>&#174</sup>',
        route: 'rules',
        accessName: 'emtr',
        showLink: this.sharedService.checkEntitlements(getUUID.emtrAccess.ruleSummary.uuid, '', '')
      },
      {
        id: 2,
        /* eslint-disable-next-line max-len */
        name: this.businessUnit === 'RBS' || this.accessForRbsOnly || (this.accessToDBSClient && this.accessToSPSClient && !this.accessWithRuleAgentDBSClient && !this.accessWithRuleAgentSPSClient) || (!this.accessToDBSClient && !this.accessToSPSClient) || (this.accessToDBSClient && !this.accessToSPSClient && !this.accessWithRuleAgentDBSClient) || (!this.accessToDBSClient && this.accessToSPSClient && !this.accessWithRuleAgentSPSClient) ? this.sharedConstant['RBS'].empParticipantSubtabName : 'Manage Employees',
        /* eslint-disable-next-line max-len */
        route: this.businessUnit === 'RBS' || this.accessForRbsOnly || (this.accessToDBSClient && this.accessToSPSClient && !this.accessWithRuleAgentDBSClient && !this.accessWithRuleAgentSPSClient) || (!this.accessToDBSClient && !this.accessToSPSClient) || (this.accessToDBSClient && !this.accessToSPSClient && !this.accessWithRuleAgentDBSClient) || (!this.accessToDBSClient && this.accessToSPSClient && !this.accessWithRuleAgentSPSClient) ? this.sharedConstant['RBS'].empParticipantMainRoute : 'manage-employees',
        accessName: 'emtr',
        showLink: this.sharedService.checkEntitlements(getUUID.addLinkAppAccess.employeeSummary.uuid, '', '')
      },
      {
        id: 3,
        /* eslint-disable-next-line max-len */
        name: this.businessUnit === 'RBS' || this.accessForRbsOnly || (this.accessToDBSClient && this.accessToSPSClient && !this.accessWithRuleAgentDBSClient && !this.accessWithRuleAgentSPSClient) || (!this.accessToDBSClient && !this.accessToSPSClient) || (this.accessToDBSClient && !this.accessToSPSClient && !this.accessWithRuleAgentDBSClient) || (!this.accessToDBSClient && this.accessToSPSClient && !this.accessWithRuleAgentSPSClient) ? this.sharedConstant['RBS'].subTabName : 'Manage Employee Groups',
        /* eslint-disable-next-line max-len */
        route: this.businessUnit === 'RBS' || this.accessForRbsOnly || (this.accessToDBSClient && this.accessToSPSClient && !this.accessWithRuleAgentDBSClient && !this.accessWithRuleAgentSPSClient) || (!this.accessToDBSClient && !this.accessToSPSClient) || (this.accessToDBSClient && !this.accessToSPSClient && !this.accessWithRuleAgentDBSClient) || (!this.accessToDBSClient && this.accessToSPSClient && !this.accessWithRuleAgentSPSClient) ? this.sharedConstant['RBS'].mainRoute : 'manage-employee-groups',
        accessName: 'emtr',
        showLink: this.sharedService.checkEntitlements(getUUID.addLinkAppAccess.employeeSummary.uuid, '', '')
      },
      {
        id: 4,
        name: this.getConstant.instrumentGroups.instrumentGroupsText,
        route: 'instrument-groups',
        accessName: 'instrumentsGrp',
        showLink: this.sharedService.checkEntitlements(getUUID.emtrAccess.instrumentsSummary.uuid, '', '')
      },
      {
        id: 5,
        name: this.getConstant.manageMasterGroups.masterGroupText,
        route: 'master-groups',
        accessName: 'masterGrp',
        showLink: this.sharedService.checkEntitlements(getUUID.emtrAccess.masterAccountGroupSummary.uuid, '', '')
      },
      ];

    /* When client is selected and the selected client does not have access to INSTRUMENTS_SUMMARY entitlement */
    if (this.clientInfo && !this.sharedService.checkEntitlements(getUUID.emtrAccess.instrumentsSummary.uuid, '', '')) {
      if (!this.sharedService.checkEntitlements(getUUID.emtrAccess.ruleSummary.uuid, getUUID.accountSummary.uuid, '')) {
        this.sharedService.loadDataTrackValue('client_redirect');
        /* If selected client does not have Rule Agent and Auth Agent role access, then navigate to Admin menu */
        this.router.navigate(['ems/admin/manage-clients']);
      } else if (!this.hasEmtrAppAcc) {
        this.sharedService.loadDataTrackValue('client_redirect');
        /* If selected client does not have Rule Agent role access, then navigate to Workbench */
        this.router.navigate(['ems/workbench']);
      }
    } else if (this.clientInfo && !this.hasEmtrAppAcc) {
      this.sharedService.loadDataTrackValue('client_redirect');
      /* If selected client does not have RBS RA/RBS RA View role access but has Instrument Group Admin role, then navigate to Instrument Group ->
      That is when RBS client is selected with IGA role access */
      this.router.navigate(['/ems/emtr/instrument-groups/instrument-group-list']);
    }
  }

  ngOnDestroy() {
    if (this.clientInfoSubscribe) {
      this.clientInfoSubscribe.unsubscribe();
    }
  }
}
